import { Helmet } from "react-helmet-async";
import Header from "./Layouts/Header";
import Footer from "./Layouts/Footer";

export default function Shop() {
  return (
    <>
      <Helmet>
        <title>War Crown | Shop</title>
      </Helmet>
      <div className="relative bg-[url('./assets/img/shop/background.jpg')] bg-no-repeat bg-contain aspect-[1920/3062]">
        <Header />
        <div className="bg-[url('./assets/img/global/top_seperator.png')] bg-no-repeat bg-contain aspect-[1920/146] mt-[34vw]" />
        <div className="pt-[2vw] pr-[3.43vw] pl-[4.43vw] uppercase text-white">
          <div className="flex justify-center gap-[8vw] bg-[url('./assets/img/shop/text_border.png')] bg-no-repeat bg-contain aspect-[1752/98] pt-[0.3vw]">
            <div className="flex gap-[6.2vw] text-[1vw] font-bold">
              <span className="font-[Gotham]">Starter Packs (3)</span>
              <span className="font-[Gotham]">L-Coins (0)</span>
            </div>
            <span className="text-[1vw] text-[#ac8c4a] font-[Gotham] font-bold mt-[0.5vw] ml-[1.2vw]">
              All(3)
            </span>
            <div className="flex gap-[6.2vw] text-[1vw] font-bold">
              <span className="font-[Gotham]">Premium Tickets (0)</span>
              <span className="font-[Gotham]">Services (0)</span>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-[1vw] px-[3vw] -mt-[1vw]">
            <div className="relative bg-[url('./assets/img/shop/boxes/standard.png')] bg-no-repeat bg-contain aspect-[391/540] -mt-[0.2vw]">
              <div className="absolute bottom-[1vw] w-full px-[2.2vw]">
                <div className="flex justify-center items-center bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain aspect-[309/107] pt-[1vw] text-[1.5vw] uppercase">
                  Buy 9.99$
                </div>
              </div>
            </div>
            <div className="relative bg-[url('./assets/img/shop/boxes/advanced.png')] bg-no-repeat bg-contain aspect-[394/534]">
              <div className="absolute bottom-[1vw] w-full px-[2.2vw]">
                <div className="flex justify-center items-center bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain aspect-[309/107] pt-[1vw] text-[1.5vw] uppercase">
                  Buy 9.99$
                </div>
              </div>
            </div>
            <div className="relative bg-[url('./assets/img/shop/boxes/advanced.png')] bg-no-repeat bg-contain aspect-[394/534]">
              <div className="absolute bottom-[1vw] w-full px-[2.2vw]">
                <div className="flex justify-center items-center bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain aspect-[309/107] pt-[1vw] text-[1.5vw] uppercase">
                  Buy 9.99$
                </div>
              </div>
            </div>
            <div className="relative bg-[url('./assets/img/shop/boxes/enchanced.png')] bg-no-repeat bg-contain aspect-[393/532]">
              <div className="absolute bottom-[1vw] w-full px-[2.2vw]">
                <div className="flex justify-center items-center bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain aspect-[309/107] pt-[1vw] text-[1.5vw] uppercase">
                  Buy 9.99$
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
