import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import { Images } from "./constants/Images";
import Loading from "./components/Loading";
import Router from "./router";
import "./App.css";

function App() {
  const [imgsLoaded, setImgsLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image);
          }, 2000);

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(Images.map((image) => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch((err) => console.log("Failed to load images", err));
  }, []);

  return <BrowserRouter>{imgsLoaded ? <Router /> : <Loading />}</BrowserRouter>;
}

export default App;
