import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Register from "./pages/Register";
import Shop from "./pages/Shop";
import Wiki from "./pages/Wiki";

export default function router() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/shop" element={<Shop />} />
      <Route exact path="/wiki" element={<Wiki />} />
    </Routes>
  );
}
