import footer_line from "../assets/img/footer/footer_line.png";
import warcrown_all_rights_reserved from "../assets/img/footer/warcrown_all_rights_reserved.png";
import create_account_button from "../assets/img/global/create_account_button.png";
import cursor_normal from "../assets/img/global/cursor_normal.png";
import cursor_selected from "../assets/img/global/cursor_selected.png";
import features_button from "../assets/img/global/features_button.png";
import language_en from "../assets/img/global/language_en.png";
import login from "../assets/img/global/login.png";
import login_selected from "../assets/img/global/login_selected.png";
import logo from "../assets/img/global/logo.png";
import shop_button from "../assets/img/global/shop_button.png";
import status from "../assets/img/global/status.png";
import title_logo from "../assets/img/global/title_logo.png";
import top_seperator from "../assets/img/global/top_seperator.png";
import arrow_down from "../assets/img/global/buttons/arrow_down.png";
import arrow_up from "../assets/img/global/buttons/arrow_up.png";
import blue from "../assets/img/global/buttons/blue.png";
import blue_left from "../assets/img/global/buttons/blue_left.png";
import blue_right from "../assets/img/global/buttons/blue_right.png";
import gold from "../assets/img/global/buttons/gold.png";
import gold_down from "../assets/img/global/buttons/gold_down.png";
import gold_left from "../assets/img/global/buttons/gold_left.png";
import gold_right from "../assets/img/global/buttons/gold_right.png";
import gold_up from "../assets/img/global/buttons/gold_up.png";
import home_background from "../assets/img/home/background.png";
import bottom_back from "../assets/img/home/bottom_back.jpg";
import start from "../assets/img/home/start.png";
import start_selected from "../assets/img/home/start_selected.png";
import google_drive from "../assets/img/home/download/google-drive.png";
import one_drive from "../assets/img/home/download/one-drive.png";
import megaz from "../assets/img/home/download/megaz.png";
import media_fire from "../assets/img/home/download/media-fire.png";
import our_concept_background from "../assets/img/home/our_concept/background.jpg";
import play_now from "../assets/img/home/our_concept/buttons/play_now.png";
import sections_1 from "../assets/img/home/our_concept/sections/1.png";
import sections_2 from "../assets/img/home/our_concept/sections/2.png";
import sections_3 from "../assets/img/home/our_concept/sections/3.png";
import ratings_background from "../assets/img/home/ratings/background.jpg";
import review_box from "../assets/img/home/ratings/review_box.png";
import bar from "../assets/img/home/sidebar/bar.png";
import selected from "../assets/img/home/sidebar/selected.png";
import none_selected from "../assets/img/home/sidebar/none_selected.png";
import the_game_selected from "../assets/img/home/sidebar/the_game_selected.png";
import the_game from "../assets/img/home/sidebar/the_game.png";
import our_concept_selected from "../assets/img/home/sidebar/our_concept_selected.png";
import our_concept from "../assets/img/home/sidebar/our_concept.png";
import streamers_selected from "../assets/img/home/sidebar/streamers_selected.png";
import streamers from "../assets/img/home/sidebar/streamers.png";
import starter_packs_selected from "../assets/img/home/sidebar/starter_packs_selected.png";
import starter_packs from "../assets/img/home/sidebar/starter_packs.png";
import server_ratings_selected from "../assets/img/home/sidebar/server_ratings_selected.png";
import server_ratings from "../assets/img/home/sidebar/server_ratings.png";
import discord from "../assets/img/home/social/discord.png";
import facebook from "../assets/img/home/social/facebook.png";
import twitch from "../assets/img/home/social/twitch.png";
import youtube from "../assets/img/home/social/youtube.png";
import instagram from "../assets/img/home/social/instagram.png";
import tiktok from "../assets/img/home/social/tiktok.png";
import starter_packs_background from "../assets/img/home/starter_packs/background.jpg";
import packs_boxes_standard from "../assets/img/home/starter_packs/packs_boxes/standard.png";
import packs_boxes_advance from "../assets/img/home/starter_packs/packs_boxes/advance.png";
import packs_boxes_enchanced from "../assets/img/home/starter_packs/packs_boxes/enchanced.png";
import paypal from "../assets/img/home/starter_packs/payment/paypal.png";
import skrill from "../assets/img/home/starter_packs/payment/skrill.png";
import coming_soon from "../assets/img/home/starter_packs/payment/coming_soon.png";
import streamers_background from "../assets/img/home/streamers/background.jpg";
import offline_box from "../assets/img/home/streamers/offline_box.png";
import the_game_background from "../assets/img/home/the_game/background.jpg";
import rates from "../assets/img/home/the_game/boxes/rates.png";
import rates_extended from "../assets/img/home/the_game/boxes/rates_extended.png";
import list_icon from "../assets/img/home/the_game/boxes/list_icon.png";
import enchant_rates from "../assets/img/home/the_game/boxes/enchant_rates.png";
import enchant_rates_extended from "../assets/img/home/the_game/boxes/enchant_rates_extended.png";
import special_access from "../assets/img/home/the_game/boxes/special_access.png";
import special_access_extended from "../assets/img/home/the_game/boxes/special_access_extended.png";
import modern_client from "../assets/img/home/the_game/boxes/modern_client.png";
import modern_client_extended from "../assets/img/home/the_game/boxes/modern_client_extended.png";
import massive_battless from "../assets/img/home/the_game/boxes/massive_battless.png";
import massive_battless_extended from "../assets/img/home/the_game/boxes/massive_battless_extended.png";
import daily_mission from "../assets/img/home/the_game/boxes/daily_mission.png";
import daily_mission_extended from "../assets/img/home/the_game/boxes/daily_mission_extended.png";
import tvt_event from "../assets/img/home/the_game/boxes/tvt_event.png";
import tvt_event_extended from "../assets/img/home/the_game/boxes/tvt_event_extended.png";
import balance_shop from "../assets/img/home/the_game/boxes/balance_shop.png";
import balance_shop_extended from "../assets/img/home/the_game/boxes/balance_shop_extended.png";
import background from "../assets/img/register/background.jpg";
import register from "../assets/img/register/register.png";
import register_clicked from "../assets/img/register/register_clicked.png";
import shop_background from "../assets/img/shop/background.jpg";
import text_border from "../assets/img/shop/text_border.png";
import boxes_advanced from "../assets/img/shop/boxes/advanced.png";
import boxes_enchanced from "../assets/img/shop/boxes/enchanced.png";
import boxes_standard from "../assets/img/shop/boxes/standard.png";
import wiki_background from "../assets/img/wiki/background.jpg";
import border_box from "../assets/img/wiki/border_box.png";
import class_balance_not_selected from "../assets/img/wiki/buttons/class_balance_not_selected.png";
import class_balance_selected from "../assets/img/wiki/buttons/class_balance_selected.png";
import enchantment_rates_not_selected from "../assets/img/wiki/buttons/enchantment_rates_not_selected.png";
import enchantment_rates_selected from "../assets/img/wiki/buttons/enchantment_rates_selected.png";
import game_client_not_selected from "../assets/img/wiki/buttons/game_client_not_selected.png";
import game_client_selected from "../assets/img/wiki/buttons/game_client_selected.png";
import information_not_selected from "../assets/img/wiki/buttons/information_not_selected.png";
import information_selected from "../assets/img/wiki/buttons/information_selected.png";
import l2_coinstore_not_selected from "../assets/img/wiki/buttons/l2_coinstore_not_selected.png";
import l2_coinstore_selected from "../assets/img/wiki/buttons/l2_coinstore_selected.png";
import power_shirt_not_selected from "../assets/img/wiki/buttons/power_shirt_not_selected.png";
import power_shirt_selected from "../assets/img/wiki/buttons/power_shirt_selected.png";
import raid_bosses_not_selected from "../assets/img/wiki/buttons/raid_bosses_not_selected.png";
import raid_bosses_selected from "../assets/img/wiki/buttons/raid_bosses_selected.png";
import streaming_rewards_not_selected from "../assets/img/wiki/buttons/streaming_rewards_not_selected.png";
import streaming_rewards_selected from "../assets/img/wiki/buttons/streaming_rewards_selected.png";
import coin_luck from "../assets/img/wiki/description/class_balance/bounty-hunter/coin-luck.png";
import rolling_dice from "../assets/img/wiki/description/class_balance/bounty-hunter/rolling-dice.png";
import avenger_fury from "../assets/img/wiki/description/class_balance/dark-avenger/avenger-fury.png";
import magic_wound from "../assets/img/wiki/description/class_balance/elven-elder/magic-wound.png";
import mantra from "../assets/img/wiki/description/class_balance/elven-elder/mantra.png";
import might_of_heaven from "../assets/img/wiki/description/class_balance/elven-elder/might-of-heaven.png";
import survival_instinct from "../assets/img/wiki/description/class_balance/hawkeye/survival-instinct.png";
import bloodlust from "../assets/img/wiki/description/class_balance/overlord/bloodlust.png";
import bloody_chain from "../assets/img/wiki/description/class_balance/overlord/bloody-chain.png";
import clear_mind from "../assets/img/wiki/description/class_balance/overlord/clear-mind.png";
import fatal_crash from "../assets/img/wiki/description/class_balance/prophet/fatal-crash.png";
import prophet_magic_wound from "../assets/img/wiki/description/class_balance/prophet/magic-wound.png";
import prophet_might_of_heaven from "../assets/img/wiki/description/class_balance/prophet/might-of-heaven.png";
import piercing from "../assets/img/wiki/description/class_balance/prophet/piercing.png";
import prophecy_of_fire from "../assets/img/wiki/description/class_balance/prophet/prophecy-of-fire.png";
import soul_collector from "../assets/img/wiki/description/class_balance/prophet/soul-collector.png";
import shillen_elder_magic_wound from "../assets/img/wiki/description/class_balance/shillen-elder/magic-wound.png";
import shillen_elder_might_of_heaven from "../assets/img/wiki/description/class_balance/shillen-elder/might-of-heaven.png";
import vampiric_fog from "../assets/img/wiki/description/class_balance/shillen-elder/vampiric-fog.png";
import corpse_burst from "../assets/img/wiki/description/class_balance/soultaker/corpse-burst.jpg";
import mass_mage_bane from "../assets/img/wiki/description/class_balance/soultaker/mass-mage-bane.jpg";
import mass_warrior_bane from "../assets/img/wiki/description/class_balance/soultaker/mass-warrior-bane.jpg";
import deadly_smash_bd from "../assets/img/wiki/description/class_balance/swordsinger/deadly-smash-bd.png";
import deadly_sweep_sws from "../assets/img/wiki/description/class_balance/swordsinger/deadly-sweep-sws.png";
import infinity_rush from "../assets/img/wiki/description/class_balance/swordsinger/infinity-rush.png";
import swordsinger_soul_collector from "../assets/img/wiki/description/class_balance/swordsinger/soul-collector.png";
import chain_hydra from "../assets/img/wiki/description/class_balance/temple-knight/chain-hydra.png";
import echo_shield from "../assets/img/wiki/description/class_balance/temple-knight/echo-shield.png";
import freezing_aura from "../assets/img/wiki/description/class_balance/temple-knight/freezing-aura.png";
import grand_provoke from "../assets/img/wiki/description/class_balance/temple-knight/grand-provoke.png";
import touch_of_eva from "../assets/img/wiki/description/class_balance/temple-knight/touch-of-eva.jpg";
import hunters_clarity from "../assets/img/wiki/description/class_balance/treasure-hunter/hunters-clarity.png";
import archers_will from "../assets/img/wiki/description/class_balance/warcryer/archers-will.jpg";
import archery from "../assets/img/wiki/description/class_balance/warcryer/archery.jpg";
import bow_mastery from "../assets/img/wiki/description/class_balance/warcryer/bow-mastery.jpg";
import full_focus from "../assets/img/wiki/description/class_balance/warcryer/full-focus.png";
import great_fury from "../assets/img/wiki/description/class_balance/warcryer/great-fury.png";
import long_shot from "../assets/img/wiki/description/class_balance/warcryer/long-shot.jpg";
import rapid_shot from "../assets/img/wiki/description/class_balance/warcryer/rapid-shot.jpg";
import eye_of_hunter from "../assets/img/wiki/description/class_balance/warsmith/eye-of-hunter.jpg";
import eye_of_slayer from "../assets/img/wiki/description/class_balance/warsmith/eye-of-slayer.jpg";
import mechanical_blow from "../assets/img/wiki/description/class_balance/warsmith/mechanical-blow.png";
import mechanical_scream from "../assets/img/wiki/description/class_balance/warsmith/mechanical-scream.png";
import provoke from "../assets/img/wiki/description/class_balance/warsmith/provoke.png";
import class_balance from "../assets/img/wiki/features/class_balance.png";
import enchantment_rates from "../assets/img/wiki/features/enchantment_rates.png";
import game_client from "../assets/img/wiki/features/game_client.png";
import information from "../assets/img/wiki/features/information.png";
import l2_coinstore from "../assets/img/wiki/features/l2_coinstore.png";
import power_shirt from "../assets/img/wiki/features/power_shirt.png";
import raid_bosses from "../assets/img/wiki/features/raid_bosses.png";
import streaming_rewards from "../assets/img/wiki/features/streaming_rewards.png";

export const Images = [
  footer_line,
  warcrown_all_rights_reserved,
  create_account_button,
  cursor_normal,
  cursor_selected,
  features_button,
  language_en,
  login,
  login_selected,
  logo,
  shop_button,
  status,
  title_logo,
  top_seperator,
  arrow_down,
  arrow_up,
  blue,
  blue_left,
  blue_right,
  gold,
  gold_down,
  gold_left,
  gold_right,
  gold_up,
  home_background,
  bottom_back,
  start,
  start_selected,
  google_drive,
  one_drive,
  megaz,
  media_fire,
  our_concept_background,
  play_now,
  sections_1,
  sections_2,
  sections_3,
  ratings_background,
  review_box,
  bar,
  selected,
  none_selected,
  the_game_selected,
  the_game,
  our_concept_selected,
  our_concept,
  streamers_selected,
  streamers,
  starter_packs_selected,
  starter_packs,
  server_ratings_selected,
  server_ratings,
  discord,
  facebook,
  twitch,
  youtube,
  instagram,
  tiktok,
  starter_packs_background,
  packs_boxes_standard,
  packs_boxes_advance,
  packs_boxes_enchanced,
  paypal,
  skrill,
  coming_soon,
  streamers_background,
  offline_box,
  the_game_background,
  rates,
  rates_extended,
  list_icon,
  enchant_rates,
  enchant_rates_extended,
  special_access,
  special_access_extended,
  modern_client,
  modern_client_extended,
  massive_battless,
  massive_battless_extended,
  daily_mission,
  daily_mission_extended,
  tvt_event,
  tvt_event_extended,
  balance_shop,
  balance_shop_extended,
  background,
  register,
  register_clicked,
  shop_background,
  text_border,
  boxes_advanced,
  boxes_enchanced,
  boxes_standard,
  wiki_background,
  border_box,
  class_balance_not_selected,
  class_balance_selected,
  enchantment_rates_not_selected,
  enchantment_rates_selected,
  game_client_not_selected,
  game_client_selected,
  information_not_selected,
  information_selected,
  l2_coinstore_not_selected,
  l2_coinstore_selected,
  power_shirt_not_selected,
  power_shirt_selected,
  raid_bosses_not_selected,
  raid_bosses_selected,
  streaming_rewards_not_selected,
  streaming_rewards_selected,
  coin_luck,
  rolling_dice,
  avenger_fury,
  magic_wound,
  mantra,
  might_of_heaven,
  survival_instinct,
  bloodlust,
  bloody_chain,
  clear_mind,
  fatal_crash,
  prophet_magic_wound,
  prophet_might_of_heaven,
  piercing,
  prophecy_of_fire,
  soul_collector,
  shillen_elder_magic_wound,
  shillen_elder_might_of_heaven,
  vampiric_fog,
  corpse_burst,
  mass_mage_bane,
  mass_warrior_bane,
  deadly_smash_bd,
  deadly_sweep_sws,
  infinity_rush,
  swordsinger_soul_collector,
  chain_hydra,
  echo_shield,
  freezing_aura,
  grand_provoke,
  touch_of_eva,
  hunters_clarity,
  archers_will,
  archery,
  bow_mastery,
  full_focus,
  great_fury,
  long_shot,
  rapid_shot,
  eye_of_hunter,
  eye_of_slayer,
  mechanical_blow,
  mechanical_scream,
  provoke,
  class_balance,
  enchantment_rates,
  game_client,
  information,
  l2_coinstore,
  power_shirt,
  raid_bosses,
  streaming_rewards,
];
