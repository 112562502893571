import { NavLink } from "react-router-dom";
import Logo from "../../components/Logo";

export default function Header() {
  return (
    <div className="flex justify-between pl-[2.7vw] pr-[1vw] pt-[0.4vw]">
      <div className="flex items-center gap-[3vw]">
        <Logo />
        <div className="bg-[url('./assets/img/global/language_en.png')] bg-no-repeat bg-contain w-[7.61vw] aspect-[146/63]" />
        <div className="relative flex justify-center items-center w-[10vw] h-[4vw]">
          <div className="absolute top-0 left-0 bg-[url('./assets/img/global/status.png')] bg-no-repeat bg-[length:100%_100%] w-full h-full opacity-15" />
          <div className="flex flex-col">
            <span className="text-[0.53vw] font-[Intro] text-[#ccc]">
              Improved H5
            </span>
            <span className="text-[0.73vw] font-[Angelus-Medieval] text-white uppercase">
              Warcrown x20
            </span>
            <span className="text-[0.63vw] font-[Intro] text-[#ffc24c]">
              Online
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-[0.5vw]">
        <NavLink
          to="/wiki"
          className="bg-[url('./assets/img/global/features_button.png')] bg-no-repeat bg-contain w-[8.96vw] aspect-[172/53] mt-[0.3vw]"
        />
        <NavLink
          to="http://account.l2warcrown.com/"
          target="_blank"
          className="bg-[url('./assets/img/global/create_account_button.png')] bg-no-repeat bg-contain w-[13.39vw] aspect-[257/56]"
        />
        <NavLink
          to="http://account.l2warcrown.com/"
          className="bg-[url('./assets/img/global/login.png')] hover:bg-[url('./assets/img/global/login_selected.png')] focus:bg-[url('./assets/img/global/login_selected.png')] bg-no-repeat bg-contain w-[17.24vw] aspect-[331/79]"
        />
      </div>
    </div>
  );
}
