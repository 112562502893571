import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { Dialog, Transition } from "@headlessui/react";
import Header from "./Layouts/Header";
import { Link } from "react-router-dom";

export default function Home() {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [section, setSection] = useState(0);
  const [concept, setConcept] = useState(0);
  const [streamer, setStreamer] = useState(0);
  const [pack, setPack] = useState(0);
  const [donation, setDonation] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  setInterval(() => {
    const cur =
      new Date("2024-06-28 22:00:00").getTime() - new Date().getTime();
    const cur_days = Math.floor(cur / (1000 * 60 * 60 * 24));
    setDays(cur_days.toString().padStart(2, "0"));
    const cur_hours = Math.floor(
      (cur % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    setHours(cur_hours.toString().padStart(2, "0"));
    const cur_minutes = Math.floor((cur % (1000 * 60 * 60)) / (1000 * 60));
    setMinutes(cur_minutes.toString().padStart(2, "0"));
    const cur_seconds = Math.floor((cur % (1000 * 60)) / 1000);
    setSeconds(cur_seconds.toString().padStart(2, "0"));
  }, 1000);

  return (
    <div className="">
      <Helmet>
        <title>War Crown | Home</title>
      </Helmet>
      <div className="bg-[url('./assets/img/home/background.png')] bg-no-repeat bg-cover aspect-[1920/1166]">
        {/* <div className="aspect-[1920/1166]">
        <video
          autoPlay
          loop
          muted
          id="video"
          className="absolute -z-10 top-0 w-full"
        >
          <source src={backgroundvideo} type="video/mp4" />
        </video> */}
        <Header />
        <div className="flex justify-between pt-[3vw] pr-[2vw] pl-[3vw]">
          <div className="flex flex-col gap-[0.73vw] w-[5.06vw]">
            <NavLink
              to="https://discord.gg/6pAPq8Awvk"
              className="bg-[url('./assets/img/home/social/discord.png')] bg-no-repeat bg-contain aspect-[97/95]"
            />
            <NavLink
              to="https://www.facebook.com/L2Warcrown/"
              className="bg-[url('./assets/img/home/social/facebook.png')] bg-no-repeat bg-contain aspect-[97/96]"
            />
            <NavLink
              to="/"
              className="bg-[url('./assets/img/home/social/twitch.png')] bg-no-repeat bg-contain aspect-[97/95]"
            />
            <NavLink
              to="/"
              className="bg-[url('./assets/img/home/social/youtube.png')] bg-no-repeat bg-contain aspect-[97/96]"
            />
            <NavLink
              to="/"
              className="bg-[url('./assets/img/home/social/instagram.png')] bg-no-repeat bg-contain aspect-[97/95]"
            />
            <NavLink
              to="https://www.tiktok.com/@l2warcrwon"
              className="bg-[url('./assets/img/home/social/tiktok.png')] bg-no-repeat bg-contain aspect-[97/96]"
            />
          </div>
          <div className="flex flex-col items-center mt-[5vw] ml-[5vw]">
            <div className="bg-[url('./assets/img/global/title_logo.png')] bg-no-repeat bg-contain w-[48.18vw] aspect-[925/305] ml-[1vw]" />
            <div className="text-center text-white -mt-[3.2vw]">
              <p className="text-[1.5vw] text-white rounded-md bg-[#ac8c4acc] border border-[#fad995] uppercase py-[0.1vw] mb-[0.5vw]">
                ENJOY THIS SUMMER
              </p>
              <div className="flex justify-center gap-[0.5vw]">
                <div className="flex flex-col justify-center items-center rounded-md bg-[#ac8c4acc] border border-[#fad995] w-[3.2vw] h-[3.2vw]">
                  <span className="text-[1.5vw]">{days}</span>
                  <span className="text-[0.8vw] -mt-[0.6vw]">days</span>
                </div>
                <div className="flex flex-col justify-center items-center rounded-md bg-[#ac8c4acc] border border-[#fad995] w-[3.2vw] h-[3.2vw]">
                  <span className="text-[1.5vw]">{hours}</span>
                  <span className="text-[0.8vw] -mt-[0.6vw]">hours</span>
                </div>
                <div className="flex flex-col justify-center items-center rounded-md bg-[#ac8c4acc] border border-[#fad995] w-[3.2vw] h-[3.2vw]">
                  <span className="text-[1.5vw]">{minutes}</span>
                  <span className="text-[0.8vw] -mt-[0.6vw]">minutes</span>
                </div>
                <div className="flex flex-col justify-center items-center rounded-md bg-[#ac8c4acc] border border-[#fad995] w-[3.2vw] h-[3.2vw]">
                  <span className="text-[1.5vw]">{seconds}</span>
                  <span className="text-[0.8vw] -mt-[0.6vw]">seconds</span>
                </div>
              </div>
            </div>
            <div
              className="bg-[url('./assets/img/home/start.png')] hover:bg-[url('./assets/img/home/start_selected.png')] bg-no-repeat bg-contain w-[27.5vw] aspect-[528/110] mt-[0.6vw]"
              onClick={() => setOpen(true)}
            />
            <Transition.Root show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-[#202028b3] bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="grid grid-cols-2 gap-2 transform overflow-hidden rounded-md bg-[#16161f] text-left shadow-xl transition-all w-full max-w-[88.1vw] px-3 py-3.5">
                        <div className="bg-[#0b333a] text-white text-center px-12 py-7 rounded-md min-h-[21vw]">
                          <p className="uppercase text-[1.4vw] mb-0.5">
                            Download game client!
                          </p>
                          <p className="text-[#ffc24c] text-[0.73vw] mb-4">
                            Download it once, and you're all set!
                          </p>
                          <p className="text-[#7c7c87] text-[0.84vw] mb-10">
                            To connect to the server, you need to download and
                            install the game client!
                          </p>
                          <div className="grid grid-cols-4 gap-7">
                            <Link
                              to="https://drive.google.com/file/d/1LAc6pTEKf432CmuyMKFG0Q9dnF715xyh/view"
                              target="_blank"
                              className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500 outline-none"
                            >
                              <div className="bg-[url('./assets/img/home/download/google-drive.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">
                                Google drive
                              </span>
                            </Link>
                            <Link
                              to="https://mega.nz/file/OMt1xRDK#gMpa8X4EcqotNARQ5R2jZhO0lYi3-3xmztJ0_mwcaVo"
                              target="_blank"
                              className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500 outline-none"
                            >
                              <div className="bg-[url('./assets/img/home/download/megaz.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">Meganz</span>
                            </Link>
                          </div>
                        </div>
                        <div className="bg-[#0b333a] text-white text-center px-12 py-7 rounded-md min-h-[21vw]">
                          <p className="uppercase text-[1.4vw] mb-0.5">
                            Download updater!
                          </p>
                          <p className="text-[#ffc24c] text-[0.73vw] mb-4">
                            Quick and easy updates!
                          </p>
                          <p className="text-[#7c7c87] text-[0.84vw] mb-10">
                            The Launcher will download only the necesary system
                            files without the full game.
                          </p>
                          <div className="grid grid-cols-4 gap-7">
                            <Link
                              to="https://drive.google.com/file/d/1OC3DnEg0FxRktaKrmiGoOEN7i5uNnfuq/view?usp=drive_link"
                              target="_blank"
                              className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500"
                            >
                              <div className="bg-[url('./assets/img/home/download/google-drive.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">
                                Google drive
                              </span>
                            </Link>
                            <Link
                              to="https://1drv.ms/u/s!At3v1mzd_-NCcKn9959WqF5XktM?e=zHVKSK"
                              target="_blank"
                              className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500"
                            >
                              <div className="bg-[url('./assets/img/home/download/one-drive.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">
                                One drive
                              </span>
                            </Link>
                            <Link
                              to="https://mega.nz/file/xy9H1QYR#vPpfOg8JM4gyVOav8pNEibhHVChZrySngA8TBR_mLi0"
                              target="_blank"
                              className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500"
                            >
                              <div className="bg-[url('./assets/img/home/download/megaz.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">Meganz</span>
                            </Link>
                            <Link
                              to="https://www.mediafire.com/file/07y2ljy4pwjo64q/Updater.exe/file"
                              target="_blank"
                              className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500"
                            >
                              <div className="bg-[url('./assets/img/home/download/media-fire.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">
                                Mediafire
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div className="bg-[#0b333a] text-white text-center px-12 py-7 rounded-md min-h-[21vw]">
                          <p className="uppercase text-[1.4vw] mb-0.5">
                            Download patch!
                          </p>
                          <p className="text-[#ffc24c] text-[0.73vw] mb-4">
                            The patch contains only system files!
                          </p>
                          <p className="text-[#7c7c87] text-[0.84vw] mb-10">
                            To connect to the server, you need to download and
                            install the game client!
                          </p>
                          <div className="grid grid-cols-4 gap-7">
                            <Link
                                to="https://drive.google.com/file/d/1iw9xOU7-9SzOmNoGR4mYDkIAgvkZyrSe/view"
                                target="_blank"
                                className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500 outline-none"
                            >
                            <div className="bg-[url('./assets/img/home/download/google-drive.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">
                                Google drive
                              </span>
                            </Link>
                            <Link
                                to="https://mega.nz/file/iU00AaAY#PyuwORpdQyIdg9mUOhyEiFFMhYKXPrmFdhiy1oR0Tys"
                                target="_blank"
                                className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500 outline-none"
                            >
                              <div className="bg-[url('./assets/img/home/download/megaz.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">Meganz</span>
                            </Link>
                            <Link
                                to="https://sourceforge.net/projects/l2warcrown/files/system-eu.zip/download"
                                target="_blank"
                                className="flex flex-col gap-4 items-center opacity-50 hover:opacity-100 transition-opacity duration-500 outline-none"
                            >
                              <div className="bg-[url('./assets/img/home/download/media-fire.png')] bg-no-repeat bg-contain w-full aspect-[164/121]" />
                              <span className="uppercase text-xs">
                                SourceForge
                              </span>
                            </Link>
                        </div>
                        <div className="flex flex-col items-center bg-[#0b333a] text-white text-center px-12 py-7 rounded-md min-h-[21vw]">
                          <p className="uppercase text-[1.4vw] mb-[2.5vw]">
                            Register account!
                          </p>
                          <p className="text-[#7c7c87] text-[0.84vw]">
                            The first step towards an interesting game is to
                            create a master account, and then play! After
                            registering the master account, enter your personal
                            account and create the required number of game
                            accounts to enter the game.
                          </p>
                          <Link
                            to="http://account.l2warcrown.com/"
                            target="_blank"
                            className="flex justify-center items-center text-white uppercase text-[1.31vw] bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[24.5vw] aspect-[309/107] pt-[1.5vw] opacity-50 hover:opacity-100 transition-opacity duration-500"
                          >
                            Register account
                          </Link>
                        </div>
                      </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
            <div className="flex flex-col text-white text-[1.5vw] text-center w-[60vw] mt-[3vw] p-[2vw] rounded-lg bg-[#0b333a80] shadow-lg shadow-teal-950">
              <span className="text-[2vw]">Welcome to Warcrown</span>
              <span>
                Where Lineage2 meets next-level graphics, cutting-edge
                mechanics, real-world rewards, and enhanced balance! Play,
                thrive, and profit from your passion with every character!
              </span>
              <span>
                Revolutionizing Lineage2 with Enhanced Balance! Say goodbye to
                underutilized characters as we introduce new abilities, ensuring
                every character thrives in combat and gameplay!
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-[2.8vw] bg-[url('./assets/img/home/sidebar/bar.png')] bg-no-repeat bg-contain bg-right h-[48vw] py-[5vw] mt-[2vw]">
            <NavHashLink
              to="#the-game"
              className="relative flex justify-end pr-[1.3vw] group"
              onClick={() => setSection(0)}
            >
              <div
                className={`absolute right-0 -top-[0.35vw] ${
                  section === 0
                    ? "bg-[url('./assets/img/home/sidebar/selected.png')] w-[7.35vw] aspect-[141/43]"
                    : "bg-[url('./assets/img/home/sidebar/none_selected.png')] w-[2vw] aspect-[39/51]"
                } group-hover:bg-[url('./assets/img/home/sidebar/selected.png')] group-hover:w-[7.35vw] group-hover:aspect-[141/43] bg-no-repeat bg-contain`}
              />
              <div
                className={`${
                  section === 0
                    ? "bg-[url('./assets/img/home/sidebar/the_game_selected.png')]"
                    : "bg-[url('./assets/img/home/sidebar/the_game.png')]"
                } group-hover:bg-[url('./assets/img/home/sidebar/the_game_selected.png')] bg-no-repeat bg-contain w-[7.77vw] aspect-[100/20.13]`}
              />
            </NavHashLink>
            <NavHashLink
              to="#our-concept"
              className="relative flex justify-end pr-[1.5vw] group"
              onClick={() => setSection(1)}
            >
              <div
                className={`absolute right-0 -top-[0.35vw] ${
                  section === 1
                    ? "bg-[url('./assets/img/home/sidebar/selected.png')] w-[7.35vw] aspect-[100/30.5]"
                    : "bg-[url('./assets/img/home/sidebar/none_selected.png')] w-[2vw] aspect-[100/130.77]"
                } group-hover:bg-[url('./assets/img/home/sidebar/selected.png')] group-hover:w-[7.35vw] group-hover:aspect-[141/43] bg-no-repeat bg-contain`}
              />
              <div
                className={`${
                  section === 1
                    ? "bg-[url('./assets/img/home/sidebar/our_concept_selected.png')]"
                    : "bg-[url('./assets/img/home/sidebar/our_concept.png')]"
                } group-hover:bg-[url('./assets/img/home/sidebar/our_concept_selected.png')] bg-no-repeat bg-contain w-[7.77vw] aspect-[100/20.13]`}
              />
            </NavHashLink>
            <NavHashLink
              to="#streamers"
              className="relative flex justify-end pr-[1.5vw] group"
              onClick={() => setSection(2)}
            >
              <div
                className={`absolute right-0 -top-[0.35vw] ${
                  section === 2
                    ? "bg-[url('./assets/img/home/sidebar/selected.png')] w-[7.35vw] aspect-[100/30.5]"
                    : "bg-[url('./assets/img/home/sidebar/none_selected.png')] w-[2vw] aspect-[100/130.77] -mr-[0.1vw]"
                } group-hover:bg-[url('./assets/img/home/sidebar/selected.png')] group-hover:w-[7.35vw] group-hover:aspect-[141/43] bg-no-repeat bg-contain`}
              />
              <div
                className={`${
                  section === 2
                    ? "bg-[url('./assets/img/home/sidebar/streamers_selected.png')]"
                    : "bg-[url('./assets/img/home/sidebar/streamers.png')]"
                } group-hover:bg-[url('./assets/img/home/sidebar/streamers_selected.png')] bg-no-repeat bg-contain w-[8.08vw] aspect-[100/20.26]`}
              />
            </NavHashLink>
            <NavHashLink
              to="#starter-packs"
              className="relative flex justify-end pr-[1.5vw] group"
              onClick={() => setSection(3)}
            >
              <div
                className={`absolute right-0 -top-[0.35vw] ${
                  section === 3
                    ? "bg-[url('./assets/img/home/sidebar/selected.png')] w-[7.35vw] aspect-[100/30.5]"
                    : "bg-[url('./assets/img/home/sidebar/none_selected.png')] w-[2vw] aspect-[100/130.77]"
                } group-hover:bg-[url('./assets/img/home/sidebar/selected.png')] group-hover:w-[7.35vw] group-hover:aspect-[141/43] bg-no-repeat bg-contain`}
              />
              <div
                className={`${
                  section === 3
                    ? "bg-[url('./assets/img/home/sidebar/starter_packs_selected.png')]"
                    : "bg-[url('./assets/img/home/sidebar/starter_packs.png')]"
                } group-hover:bg-[url('./assets/img/home/sidebar/starter_packs_selected.png')] bg-no-repeat bg-contain w-[8.91vw] aspect-[100/18.13]`}
              />
            </NavHashLink>
            <NavHashLink
              to="#ratings"
              className="relative flex justify-end pr-[1.5vw] group"
              onClick={() => setSection(4)}
            >
              <div
                className={`absolute right-0 -top-[0.35vw] ${
                  section === 4
                    ? "bg-[url('./assets/img/home/sidebar/selected.png')] w-[7.35vw] aspect-[100/30.5]"
                    : "bg-[url('./assets/img/home/sidebar/none_selected.png')] w-[2vw] aspect-[100/130.77]"
                } group-hover:bg-[url('./assets/img/home/sidebar/selected.png')] group-hover:w-[7.35vw] group-hover:aspect-[141/43] bg-no-repeat bg-contain`}
              />
              <div
                className={`${
                  section === 4
                    ? "bg-[url('./assets/img/home/sidebar/server_ratings_selected.png')]"
                    : "bg-[url('./assets/img/home/sidebar/server_ratings.png')]"
                } group-hover:bg-[url('./assets/img/home/sidebar/server_ratings_selected.png')] bg-no-repeat bg-contain w-[9.48vw] aspect-[100/16.48]`}
              />
            </NavHashLink>
          </div>
        </div>
      </div>
      <div
        id="the-game"
        className="bg-[url('./assets/img/home/the_game/background.jpg')] bg-no-repeat bg-contain aspect-[1920/1050] grid grid-cols-4 gap-x-[4vw] pt-[12vw] pl-[15.2vw] pr-[15.5vw]"
      >
        <div className="relative group">
          <div className="group-hover:hidden bg-[url('./assets/img/home/the_game/boxes/rates.png')] bg-no-repeat bg-contain aspect-[264/333] pt-[9.8vw]">
            <div className="flex justify-center items-center font-[Gotham] font-bold uppercase text-[1.8vw] leading-[1.4vw] text-white text-center -mb-[1.8vw] min-h-[2.8vw] px-[2vw]">
              Rates
            </div>
            <div className="flex justify-center items-center text-white text-[1.27vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[120%] aspect-[309/107] pt-[1vw] -ml-[10%]">
              Read more
            </div>
          </div>
          <div className="absolute z-10 hidden group-hover:block bg-[url('./assets/img/home/the_game/boxes/rates_extended.png')] bg-no-repeat bg-contain aspect-[264/439] text-white p-[0.7vw] pt-[1vw]">
            <div className="min-h-[3vw] uppercase text-center">
              <div className="font-[Gotham] font-bold text-[1.8vw] leading-[1.4vw]">
                Rates
              </div>
              <div className="font-[Gotham] font-bold text-[0.8vw] leading-[1vw]">
                Mid rate experience
              </div>
            </div>
            <div className="font-[Gotham] text-[0.78vw] leading-[1vw] text-center min-h-[4.5vw]">
              The progress speed was adjusted to a comfortable gameplay.
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Experience: 20x
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Skill Points: 20x
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Adena Drop: 10x
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Item Drop: 7x
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Spoil Rate: 3x
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Raid Boss Exp: 5x
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Raid Boss Drop: 3x
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Epic Boss Drop: 1x
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Quest Reward Items/Adena: 3x
            </div>
          </div>
        </div>
        <div className="relative group">
          <div className="group-hover:hidden bg-[url('./assets/img/home/the_game/boxes/enchant_rates.png')] bg-no-repeat bg-contain aspect-[264/333] pt-[9.8vw]">
            <div className="flex justify-center items-center font-[Gotham] font-bold uppercase text-[1.8vw] leading-[1.4vw] text-white text-center -mb-[1.8vw] min-h-[2.8vw] px-[2vw]">
              Enchant Rates
            </div>
            <div className="flex justify-center items-center text-white text-[1.27vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[120%] aspect-[309/107] pt-[1vw] -ml-[10%]">
              Read more
            </div>
          </div>
          <div className="absolute z-10 hidden group-hover:block bg-[url('./assets/img/home/the_game/boxes/enchant_rates_extended.png')] bg-no-repeat bg-contain aspect-[264/439] text-white p-[0.7vw] pt-[1vw]">
            <div className="min-h-[3vw] uppercase text-center">
              <div className="font-[Gotham] font-bold text-[1.8vw] leading-[1.4vw]">
                Enchant Rates
              </div>
            </div>
            <div className="font-[Gotham] text-[0.78vw] leading-[1vw] text-center min-h-[4.5vw]"></div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Safe Enchant: +4
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Safe Enchant Full-Armor: +4
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Max Enchant: +16
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Enchanting Chance:
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Fighter Weapons: 66%
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Mage Weapon: 40%
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Armor and Jeweles: 50%
            </div>
          </div>
        </div>
        <div className="relative group">
          <div className="group-hover:hidden bg-[url('./assets/img/home/the_game/boxes/special_access.png')] bg-no-repeat bg-contain aspect-[264/333] pt-[9.8vw]">
            <div className="flex justify-center items-center font-[Gotham] font-bold uppercase text-[1.8vw] leading-[1.4vw] text-white text-center -mb-[1.8vw] min-h-[2.8vw] px-[2vw]">
              Special Access
            </div>
            <div className="flex justify-center items-center text-white text-[1.27vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[120%] aspect-[309/107] pt-[1vw] -ml-[10%]">
              Read more
            </div>
          </div>
          <div className="absolute z-10 hidden group-hover:block bg-[url('./assets/img/home/the_game/boxes/special_access_extended.png')] bg-no-repeat bg-contain aspect-[264/439] text-white p-[0.7vw] pt-[1vw]">
            <div className="uppercase text-center">
              <div className="font-[Gotham] font-bold text-[1.8vw] leading-[1.4vw]">
                Special Access
              </div>
            </div>
            <div className="font-[Gotham] font-bold text-[0.8vw] leading-[1vw] mb-[1vw]">
              All players have access to our community board with the command
              ALT+B where they can find great accesses that will allow them to
              make the game more comfortable for them.
            </div>
            <div className="relative font-[Gotham] text-[0.78vw] leading-[1vw]">
              <div className="absolute top-[0.2vw] bg-[url('./assets/img/home/the_game/boxes/list_icon.png')] bg-no-repeat bg-contain w-[1vw] aspect-[19/13]"></div>
              &nbsp;&nbsp;&nbsp;&nbsp;Access to the World trade: players will be
              able to sell their items for real money. Adena Shop: up to grade S
              Premium Shop Autobuffer Services Class Change
            </div>
          </div>
        </div>
        <div className="relative group">
          <div className="group-hover:hidden bg-[url('./assets/img/home/the_game/boxes/modern_client.png')] bg-no-repeat bg-contain aspect-[264/333] pt-[9.8vw]">
            <div className="flex justify-center items-center font-[Gotham] font-bold uppercase text-[1.8vw] leading-[1.4vw] text-white text-center -mb-[1.8vw] min-h-[2.8vw] px-[2vw]">
              Modern Client
            </div>
            <div className="flex justify-center items-center text-white text-[1.27vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[120%] aspect-[309/107] pt-[1vw] -ml-[10%]">
              Read more
            </div>
          </div>
          <div className="absolute z-10 hidden group-hover:block bg-[url('./assets/img/home/the_game/boxes/modern_client_extended.png')] bg-no-repeat bg-contain aspect-[264/439] text-white p-[0.7vw] pt-[1vw]">
            <div className="min-h-[6vw] uppercase text-center">
              <div className="font-[Gotham] font-bold text-[1.8vw] leading-[1.4vw]">
                Modern Client
              </div>
            </div>
            <div className="font-[Gotham] text-[0.78vw] leading-[1vw] text-center min-h-[4.5vw]">
              You will play on WARCROWN with new game version, but only the
              features that don't affect the gameplay are enabled. Health bar,
              damage visualization, mini map, party tokens, debuffs landed on
              monsters and many other features are available, while auto hunting
              is disabled.
            </div>
          </div>
        </div>
        <div className="relative group">
          <div className="group-hover:hidden bg-[url('./assets/img/home/the_game/boxes/massive_battless.png')] bg-no-repeat bg-contain aspect-[264/333] pt-[9.8vw]">
            <div className="flex justify-center items-center font-[Gotham] font-bold uppercase text-[1.8vw] leading-[1.4vw] text-white text-center -mb-[1.8vw] min-h-[2.8vw] px-[2vw]">
              Massive Battless
            </div>
            <div className="flex justify-center items-center text-white text-[1.27vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[120%] aspect-[309/107] pt-[1vw] -ml-[10%]">
              Read more
            </div>
          </div>
          <div className="absolute z-10 hidden bg-[url('./assets/img/home/the_game/boxes/massive_battless_extended.png')] bg-no-repeat bg-contain aspect-[264/439] group-hover:block text-white p-[0.7vw] pt-[1vw]">
            <div className="min-h-[6vw] uppercase text-center">
              <div className="font-[Gotham] font-bold text-[1.8vw] leading-[1.4vw]">
                Massive Battless
              </div>
            </div>
            <div className="font-[Gotham] text-[0.78vw] leading-[1vw] text-center min-h-[4.5vw]">
              We want players to experience great battles, to encourage PvP
              action, new heroes take place every week. All players will have
              access to the visibility of the raids available to fight for them
              and sieges will be held every week. with our class balancing
              system all characters can be used for pvp
            </div>
          </div>
        </div>
        <div className="relative group">
          <div className="group-hover:hidden bg-[url('./assets/img/home/the_game/boxes/daily_mission.png')] bg-no-repeat bg-contain aspect-[264/333] pt-[9.8vw]">
            <div className="flex justify-center items-center font-[Gotham] font-bold uppercase text-[1.8vw] leading-[1.4vw] text-white text-center -mb-[1.8vw] min-h-[2.8vw] px-[2vw]">
              Daily Mission
            </div>
            <div className="flex justify-center items-center text-white text-[1.27vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[120%] aspect-[309/107] pt-[1vw] -ml-[10%]">
              Read more
            </div>
          </div>
          <div className="absolute z-10 hidden bg-[url('./assets/img/home/the_game/boxes/daily_mission_extended.png')] bg-no-repeat bg-contain aspect-[264/439] group-hover:block text-white p-[0.7vw] pt-[1vw]">
            <div className="min-h-[8vw] uppercase text-center">
              <div className="font-[Gotham] font-bold text-[1.8vw] leading-[1.4vw]">
                Daily Mission
              </div>
            </div>
            <div className="font-[Gotham] text-[0.78vw] leading-[1vw] text-center min-h-[4.5vw]">
              On our server you will be able to enjoy daily missions that will
              allow you to have a more progressive gameplay.
            </div>
          </div>
        </div>
        <div className="relative group">
          <div className="group-hover:hidden bg-[url('./assets/img/home/the_game/boxes/tvt_event.png')] bg-no-repeat bg-contain aspect-[264/333] pt-[9.8vw]">
            <div className="flex justify-center items-center font-[Gotham] font-bold uppercase text-[1.8vw] leading-[1.4vw] text-white text-center -mb-[1.8vw] min-h-[2.8vw] px-[2vw]">
              Tvt Event
            </div>
            <div className="flex justify-center items-center text-white text-[1.27vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[120%] aspect-[309/107] pt-[1vw] -ml-[10%]">
              Read more
            </div>
          </div>
          <div className="absolute z-10 hidden bg-[url('./assets/img/home/the_game/boxes/tvt_event_extended.png')] bg-no-repeat bg-contain aspect-[264/439] group-hover:block text-white p-[0.7vw] pt-[1vw]">
            <div className="min-h-[7vw] uppercase text-center">
              <div className="font-[Gotham] font-bold text-[1.8vw] leading-[1.4vw]">
                Tvt Event
              </div>
            </div>
            <div className="font-[Gotham] text-[0.76vw] leading-[1vw] text-center min-h-[4.5vw]">
              In WarCrown we have the best PVP and Mass PVP events, where you
              can get great prizes and enjoy yourself as a team and as an
              individual.
            </div>
          </div>
        </div>
        <div className="relative group">
          <div className="group-hover:hidden bg-[url('./assets/img/home/the_game/boxes/balance_shop.png')] bg-no-repeat bg-contain aspect-[264/333] pt-[9.8vw]">
            <div className="flex justify-center items-center font-[Gotham] font-bold uppercase text-[1.8vw] leading-[1.4vw] text-white text-center -mb-[1.8vw] min-h-[2.8vw] px-[2vw]">
              Balance Shop
            </div>
            <div className="flex justify-center items-center text-white text-[1.27vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[120%] aspect-[309/107] pt-[1vw] -ml-[10%]">
              Read more
            </div>
          </div>
          <div className="absolute z-10 hidden group-hover:block bg-[url('./assets/img/home/the_game/boxes/balance_shop_extended.png')] bg-no-repeat bg-contain aspect-[264/439] text-white p-[0.7vw] pt-[1vw]">
            <div className="min-h-[7vw] uppercase text-center">
              <div className="font-[Gotham] font-bold text-[1.8vw] leading-[1.4vw]">
                Balance Shop
              </div>
            </div>
            <div className="font-[Gotham] text-[0.7vw] leading-[1vw] text-center min-h-[4.5vw]">
              NO P2W! No boosters for donations, you have to farm everything by
              yourself in our server! The only thing you can donate in our
              server is for Premium Account, Cosmetics and some custom things on
              the server itself without influencing the game.
            </div>
          </div>
        </div>
      </div>
      <div
        id="our-concept"
        className="relative bg-[url('./assets/img/home/our_concept/background.jpg')] bg-no-repeat bg-contain aspect-[1920/806] pt-[4vw] pl-[4vw]"
      >
        {concept === 0 && (
          <>
            <div className="relative bg-[url('./assets/img/home/our_concept/sections/1.png')] bg-no-repeat bg-contain w-[83.18vw] aspect-[1597/584]">
              <div className="absolute top-[10.3vw] left-[13.8vw] bg-[url('./assets/img/home/our_concept/buttons/play_now.png')] bg-no-repeat bg-contain w-[18.13vw] aspect-[348/186]"></div>
            </div>
            <div className="absolute bottom-[8.7vw] right-[11vw] flex flex-col text-white w-[36.5vw]">
              <span className="text-[2.24vw] leading-[2.24vw]">
                &nbsp;&nbsp;&nbsp;&nbsp;NO BOTS! NO P2WIN! NO WIPES! BALANCED
                CLASSES!
              </span>
              <span className="font-[Gotham] text-[1.46vw] leading-[1.8vw]">
                The main difference between L2Warcrown x20 craft-pvp and other
                mid-rate servers is that here you can enjoy playing all
                characters thanks to our balanced system. We do not allow bots
                and strive to extend the lifespan of our servers as much as
                possible. We value your time!
              </span>
            </div>
          </>
        )}
        {concept === 1 && (
          <>
            <div className="relative bg-[url('./assets/img/home/our_concept/sections/2.png')] bg-no-repeat bg-contain w-[83.18vw] aspect-[1597/584]">
              <div className="absolute top-[10.3vw] left-[13.8vw] bg-[url('./assets/img/home/our_concept/buttons/play_now.png')] bg-no-repeat bg-contain w-[18.13vw] aspect-[348/186]"></div>
            </div>
            <div className="absolute bottom-[14.6vw] right-[13vw] flex flex-col text-white w-[34.5vw]">
              <span className="text-[2.24vw] leading-[2.24vw]">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HIGH FIVE IMPROVED
                GAME
              </span>
              <span className="font-[Gotham] text-[1.46vw] leading-[1.8vw]">
                That old Lineage 2, but with updated graphics, improved
                functionality and modern mechanics. NPC Buffer 2 hours, Gmshop
                up to C grade, Automatic Events, Auto learns skills, Auto loot,
                Olympiad Every 2 Weeks.
              </span>
            </div>
          </>
        )}
        {concept === 2 && (
          <>
            <div className="relative bg-[url('./assets/img/home/our_concept/sections/3.png')] bg-no-repeat bg-contain w-[83.18vw] aspect-[1597/584]">
              <div className="absolute top-[10.3vw] left-[13.8vw] bg-[url('./assets/img/home/our_concept/buttons/play_now.png')] bg-no-repeat bg-contain w-[18.13vw] aspect-[348/186]"></div>
            </div>
            <div className="absolute bottom-[18.2vw] right-[15vw] flex flex-col text-white w-[32.5vw]">
              <span className="text-[2.24vw] leading-[2.24vw]">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SALVATION
                CLIENT WITH AUTOFARM
              </span>
              <span className="font-[Gotham] text-[1.46vw] leading-[1.8vw]">
                WarCrown provides a fully implemented Salvation Client: improved
                graphics, a new interface, Autofarm with limited time of 6 hours
                per day.
              </span>
            </div>
          </>
        )}
        <div className="absolute bottom-0 left-0 flex justify-center gap-[2vw] w-full">
          <div
            className="bg-[url('./assets/img/global/buttons/blue_left.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[53/58]"
            onClick={() => concept > 0 && setConcept(concept - 1)}
          ></div>
          <div
            className="bg-[url('./assets/img/global/buttons/blue_right.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[53/58]"
            onClick={() => concept < 2 && setConcept(concept + 1)}
          ></div>
        </div>
      </div>
      <div
        id="streamers"
        className="bg-[url('./assets/img/home/streamers/background.jpg')] bg-no-repeat bg-contain aspect-[1920/777] pt-[10.2vw] pr-[3vw] pl-[4vw]"
      >
        <div className="flex justify-end pr-[18vw]">
          <div
            className="bg-[url('./assets/img/global/buttons/gold_left.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[66/67]"
            onClick={() => streamer > 0 && setStreamer(streamer - 1)}
          ></div>
          <div
            className="bg-[url('./assets/img/global/buttons/gold_right.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[66/67]"
            onClick={() => streamer < 9 && setStreamer(streamer + 1)}
          ></div>
        </div>
        <div className="grid grid-cols-3 mt-[0.5vw]">
          <div className="bg-[url('./assets/img/home/streamers/offline_box.png')] bg-no-repeat bg-contain aspect-[605/366]"></div>
          <div className="bg-[url('./assets/img/home/streamers/offline_box.png')] bg-no-repeat bg-contain aspect-[605/366]"></div>
          <div className="bg-[url('./assets/img/home/streamers/offline_box.png')] bg-no-repeat bg-contain aspect-[605/366]"></div>
        </div>
      </div>
      <div
        id="starter-packs"
        className="bg-[url('./assets/img/home/starter_packs/background.jpg')] bg-no-repeat bg-contain aspect-[1920/1740] pt-[12.8vw] pr-[4vw] pl-[7.5vw]"
      >
        <div className="flex justify-end pr-[17vw]">
          <div
            className="bg-[url('./assets/img/global/buttons/gold_left.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[66/67]"
            onClick={() => pack > 0 && setPack(pack - 1)}
          ></div>
          <div
            className="bg-[url('./assets/img/global/buttons/gold_right.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[66/67]"
            onClick={() => pack < 9 && setPack(pack + 1)}
          ></div>
        </div>
        <div className="grid grid-cols-3 gap-[5vw] mt-[2vw] ml-[3vw]">
          <div className="flex justify-center items-end bg-[url('./assets/img/home/starter_packs/packs_boxes/standard.png')] bg-no-repeat bg-contain aspect-[476/645] pb-[1.5vw]">
            <div className="flex justify-center items-center text-white text-[1.46vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[16.1vw] aspect-[309/107] pt-[0.8vw]">
              Buy 9.99$
            </div>
          </div>
          <div className="flex justify-center items-end bg-[url('./assets/img/home/starter_packs/packs_boxes/advance.png')] bg-no-repeat bg-contain aspect-[476/645] pb-[1.5vw]">
            <div className="flex justify-center items-center text-white text-[1.46vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[16.1vw] aspect-[309/107] pt-[0.8vw]">
              Buy 19.99$
            </div>
          </div>
          <div className="flex justify-center items-end bg-[url('./assets/img/home/starter_packs/packs_boxes/enchanced.png')] bg-no-repeat bg-contain aspect-[476/645] pb-[1.5vw]">
            <div className="flex justify-center items-center text-white text-[1.46vw] uppercase bg-[url('./assets/img/global/buttons/gold.png')] bg-no-repeat bg-contain w-[16.1vw] aspect-[309/107] pt-[0.8vw]">
              Buy 34.99$
            </div>
          </div>
        </div>
        <div className="flex justify-end pr-[17vw] mt-[9.3vw]">
          <div
            className="bg-[url('./assets/img/global/buttons/gold_left.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[66/67]"
            onClick={() => donation > 0 && setDonation(donation - 1)}
          ></div>
          <div
            className="bg-[url('./assets/img/global/buttons/gold_right.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[66/67]"
            onClick={() => donation < 9 && setDonation(donation + 1)}
          ></div>
        </div>
        <div className="grid grid-cols-4 gap-[3vw] mt-[2vw] mr-[2vw]">
          <div className="flex justify-center items-end bg-[url('./assets/img/home/starter_packs/payment/paypal.png')] bg-no-repeat bg-contain aspect-[343/229] pb-[0.5vw]">
            <div className="flex justify-center items-center text-white text-[1.25vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[14.5vw] aspect-[309/107] pt-[0.7vw]">
              Select
            </div>
          </div>
          <div className="flex justify-center items-end bg-[url('./assets/img/home/starter_packs/payment/skrill.png')] bg-no-repeat bg-contain aspect-[343/229] pb-[0.5vw]">
            <div className="flex justify-center items-center text-white text-[1.25vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[14.5vw] aspect-[309/107] pt-[0.7vw]">
              Select
            </div>
          </div>
          <div className="flex justify-center items-end bg-[url('./assets/img/home/starter_packs/payment/paypal.png')] bg-no-repeat bg-contain aspect-[343/229] pb-[0.5vw]">
            <div className="flex justify-center items-center text-white text-[1.25vw] uppercase bg-[url('./assets/img/global/buttons/blue.png')] bg-no-repeat bg-contain w-[14.5vw] aspect-[309/107] pt-[0.7vw]">
              Select
            </div>
          </div>
          <div className="flex justify-center items-end bg-[url('./assets/img/home/starter_packs/payment/coming_soon.png')] bg-no-repeat bg-contain aspect-[343/229] pb-[0.2vw]"></div>
        </div>
      </div>
      <div
        id="ratings"
        className="flex flex-col justify-center items-center bg-[url('./assets/img/home/ratings/background.jpg')] bg-no-repeat bg-contain aspect-[1920/1510] pt-[5vw]"
      >
        <div
          className="bg-[url('./assets/img/global/buttons/gold_up.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[73/57]"
          onClick={() => pack > 0 && setPack(pack - 1)}
        ></div>
        <div className="flex justify-center items-center text-white text-[1.8vw] text-center font-[Gotham] leading-[2vw] bg-[url('./assets/img/home/ratings/review_box.png')] bg-no-repeat bg-contain w-[64vw] aspect-[1244/224] px-[15vw]">
          One of the best servers out there!
        </div>
        <div className="flex justify-center items-center text-white text-[1.8vw] text-center font-[Gotham] leading-[2vw] bg-[url('./assets/img/home/ratings/review_box.png')] bg-no-repeat bg-contain w-[64vw] aspect-[1244/224] px-[15vw]">
          I really enjoyed playing WAR CROWN, I recommend it with all my heart.
        </div>
        <div className="flex justify-center items-center text-white text-[1.8vw] text-center font-[Gotham] leading-[2vw] bg-[url('./assets/img/home/ratings/review_box.png')] bg-no-repeat bg-contain w-[64vw] aspect-[1244/224] px-[15vw]">
          Different, amazing and enjoyable, come and play WAR CROWN
        </div>
        <div
          className="bg-[url('./assets/img/global/buttons/gold_down.png')] bg-no-repeat bg-contain w-[2.76vw] aspect-[73/57]"
          onClick={() => pack < 9 && setPack(pack + 1)}
        ></div>
      </div>
      <div className="flex items-center bg-[url('./assets/img/home/bottom_back.jpg')] bg-no-repeat bg-cover aspect-[1920/266] pb-[2vw] pl-[5vw]">
        <div className="bg-[url('./assets/img/footer/warcrown_all_rights_reserved.png')] bg-no-repeat bg-contain w-[38.03vw] aspect-[730/134]" />
      </div>
      <a href="https://l2.hopzone.net/site/vote/106035/1">
        <img
          src="https://l2.hopzone.net/assets/img/banners/vote_banners/banner_5.png"
          alt="Vote for L2WARCROWN.COM"
          title="L2.HOPZONE.NET – Lineage 2 Servers ranking"
          style={{
            border: 0,
            position: "fixed",
            zIndex: 99999,
            bottom: 0,
            right: 0,
            width: "140px",
            height: "120px",
          }}
        />
      </a>
    </div>
  );
}
