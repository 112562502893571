import { Helmet } from "react-helmet-async";

export default function Register() {
  return (
    <div className="overflow-y-hidden">
      <Helmet>
        <title>War Crown | Register</title>
      </Helmet>
      <div className="bg-[url('./assets/img/register/background.jpg')] bg-no-repeat bg-contain aspect-[1920/1080]">
        <div className="w-[37vw] pl-[2vw] pr-[3vw] pt-[11.4vw]">
          <span className="block text-[#ac8c4a] uppercase text-[2.35vw]">
            Email
          </span>
          <div>
            <span className="block text-[#ac8c4a] text-[2.35vw]">Name</span>
            <span className="block text-[#ac8c4a] text-[1.04vw] -mt-[1vw]">
              (use Latin letters and enter no more than 14 characters)
            </span>
            <input
              type="text"
              className="border border-[#ac8c4a] bg-transparent rounded-md w-full h-[3.2vw] outline-none text-[2vw] text-[#ac8c4a] px-[0.5vw]"
            />
          </div>
          <div className="mt-[0.1vw]">
            <span className="block text-[#ac8c4a] text-[2.35vw]">E-Mail</span>
            <input
              type="email"
              className="border border-[#ac8c4a] bg-transparent rounded-md w-full h-[3.2vw] -mt-[0.2vw] outline-none text-[2vw] text-[#ac8c4a] px-[0.5vw]"
            />
          </div>
          <div className="-mt-[0.2vw]">
            <span className="block text-[#ac8c4a] text-[2.35vw]">Password</span>
            <span className="block text-[#ac8c4a] text-[1.04vw] -mt-[1vw]">
              (use latin letters and enter at least 8 characters)
            </span>
            <input
              type="password"
              className="border border-[#ac8c4a] bg-transparent rounded-md w-full h-[3.2vw] mt-[0.4vw] outline-none text-[2vw] text-[#ac8c4a] px-[0.5vw]"
            />
          </div>
          <div className="-mt-[0.2vw]">
            <span className="block text-[#ac8c4a] text-[2.35vw]">
              Confirm the password
            </span>
            <input
              type="password"
              className="border border-[#ac8c4a] bg-transparent rounded-md w-full h-[3.2vw] mt-[0.2vw] outline-none text-[2vw] text-[#ac8c4a] px-[0.5vw]"
            />
          </div>
          <div className="pt-[2vw] px-[3.37vw]">
            <div className="bg-[url('./assets/img/register/register.png')] hover:bg-[url('./assets/img/register/register_clicked.png')] bg-no-repeat bg-contain aspect-[485/94]" />
          </div>
        </div>
      </div>
    </div>
  );
}
