import { NavLink } from "react-router-dom";

export default function Logo() {
  return (
    <NavLink
      to="/"
      className="bg-[url('./assets/img/global/logo.png')] bg-no-repeat bg-contain w-[5.68vw] aspect-[109/110]"
    />
  );
}
