import { useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "./Layouts/Header";
import Footer from "./Layouts/Footer";

export default function Wiki() {
  const [features, setFeatures] = useState(0);

  return (
    <>
      <Helmet>
        <title>War Crown | wiki</title>
      </Helmet>
      <div className="relative bg-[url('./assets/img/wiki/background.jpg')] bg-no-repeat bg-contain aspect-[1920/3062]">
        <Header />
        <div className="bg-[url('./assets/img/global/top_seperator.png')] bg-no-repeat bg-contain aspect-[1920/146] mt-[34vw]" />
        <div className="flex pl-[6vw] px-[4vw]">
          <div className="bg-[url('./assets/img/wiki/border_box.png')] bg-no-repeat bg-contain w-[38.96vw] aspect-[748/1875] pt-[10.3vw] pl-[3.5vw] -mt-[4.5vw] -mr-[5.5vw]">
            {features === 0 ? (
              <div className="bg-[url('./assets/img/wiki/buttons/information_selected.png')] bg-no-repeat bg-contain  w-[27.3vw] aspect-[524/167]" />
            ) : (
              <div
                className="bg-[url('./assets/img/wiki/buttons/information_not_selected.png')] bg-no-repeat bg-contain  w-[27.3vw] aspect-[524/167]"
                onClick={() => setFeatures(0)}
              />
            )}
            {features === 1 ? (
              <div className="bg-[url('./assets/img/wiki/buttons/enchantment_rates_selected.png')] bg-no-repeat bg-contain w-[27.61vw] aspect-[530/167] mt-[1vw]" />
            ) : (
              <div
                className="bg-[url('./assets/img/wiki/buttons/enchantment_rates_not_selected.png')] bg-no-repeat bg-contain w-[27.61vw] aspect-[530/167] mt-[1vw]"
                onClick={() => setFeatures(1)}
              />
            )}
            {features === 2 ? (
              <div className="bg-[url('./assets/img/wiki/buttons/game_client_selected.png')] bg-no-repeat bg-contain w-[27.3vw] aspect-[524/167] mt-[1vw]" />
            ) : (
              <div
                className="bg-[url('./assets/img/wiki/buttons/game_client_not_selected.png')] bg-no-repeat bg-contain w-[27.3vw] aspect-[524/167] mt-[1vw]"
                onClick={() => setFeatures(2)}
              />
            )}
            {features === 3 ? (
              <div className="bg-[url('./assets/img/wiki/buttons/class_balance_selected.png')] bg-no-repeat bg-contain w-[27.71vw] aspect-[532/175] mt-[0.5vw] -ml-[0.4vw]" />
            ) : (
              <div
                className="bg-[url('./assets/img/wiki/buttons/class_balance_not_selected.png')] bg-no-repeat bg-contain w-[27.71vw] aspect-[532/175] mt-[0.5vw] -ml-[0.4vw]"
                onClick={() => setFeatures(3)}
              />
            )}
            {features === 4 ? (
              <div className="bg-[url('./assets/img/wiki/buttons/streaming_rewards_selected.png')] bg-no-repeat bg-contain w-[27.45vw] aspect-[527/167] mt-[1vw]" />
            ) : (
              <div
                className="bg-[url('./assets/img/wiki/buttons/streaming_rewards_not_selected.png')] bg-no-repeat bg-contain w-[27.45vw] aspect-[527/167] mt-[1vw]"
                onClick={() => setFeatures(4)}
              />
            )}
            {features === 5 ? (
              <div className="bg-[url('./assets/img/wiki/buttons/raid_bosses_selected.png')] bg-no-repeat bg-contain w-[27.3vw] aspect-[524/167] mt-[1vw]" />
            ) : (
              <div
                className="bg-[url('./assets/img/wiki/buttons/raid_bosses_not_selected.png')] bg-no-repeat bg-contain w-[27.3vw] aspect-[524/167] mt-[1vw]"
                onClick={() => setFeatures(5)}
              />
            )}
            {features === 6 ? (
              <div className="bg-[url('./assets/img/wiki/buttons/power_shirt_selected.png')] bg-no-repeat bg-contain w-[27.3vw] aspect-[524/167] mt-[0.8vw]" />
            ) : (
              <div
                className="bg-[url('./assets/img/wiki/buttons/power_shirt_not_selected.png')] bg-no-repeat bg-contain w-[27.3vw] aspect-[524/167] mt-[0.8vw]"
                onClick={() => setFeatures(6)}
              />
            )}
            {features === 7 ? (
              <div className="bg-[url('./assets/img/wiki/buttons/l2_coinstore_selected.png')] bg-no-repeat bg-contain w-[23.7vw] aspect-[455/152] mt-[1vw]" />
            ) : (
              <div
                className="bg-[url('./assets/img/wiki/buttons/l2_coinstore_not_selected.png')] bg-no-repeat bg-contain w-[23.7vw] aspect-[455/152] mt-[1vw]"
                onClick={() => setFeatures(7)}
              />
            )}
          </div>
          {features === 0 && (
            <div className="flex flex-col flex-1">
              <div className="mt-[1.8vw] bg-[url('./assets/img/wiki/features/information.png')] bg-no-repeat bg-contain w-[55.63vw] aspect-[1068/504]" />
              <div className="flex flex-col text-white text-[1vw] w-[93.2%] rounded-lg bg-[#0b333a4D] ml-[2.2vw] px-[2vw] py-[1vw] max-h-[57vw] overflow-auto custom-bar">
                <div className="flex flex-col text-center">
                  <span className="text-[1.41vw]">Welcome to Warcrown</span>
                  <span className="opacity-50 font-[Gotham]">
                    Where Lineage2 meets next-level graphics, cutting-edge
                    mechanics, real-world rewards, and enhanced balance! Play,
                    thrive, and profit from your passion with every character!
                  </span>
                  <span className="opacity-50 font-[Gotham]">
                    Revolutionizing Lineage2 with Enhanced Balance! Say goodbye
                    to underutilized characters as we introduce new abilities,
                    ensuring every character thrives in combat and gameplay!
                  </span>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Server details
                  </div>
                  <ul className="list-disc ml-[1vw]">
                    <li className="opacity-50 font-[Gotham]">
                      Chronicle: Improved High Five
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Game Client: Salvation
                    </li>
                    <li className="opacity-50 font-[Gotham]">Platform: L2J</li>
                    <li className="opacity-50 font-[Gotham]">
                      Limit clients per HWID: 2 with restriccions
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Game Protection: Yes
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Game Launcher: Yes
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      DDoS Protected Proxies: EU, RU, BR, NA, UAE, ASIA
                    </li>
                  </ul>
                </div>
                <div className="mt-[2vw]">
                  <span className="block bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    DUALBOX DETAILS
                  </span>
                  <span className="block font-[Gotham]">
                    DUALBOX is allowed with restrictions:
                  </span>
                  <ul className="list-disc ml-[1vw]">
                    <li className="opacity-50 font-[Gotham]">
                      You can log 2 accounts per pc but with the following
                      restrictions
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      You cannot dual box in PvP (your 2nd char will automatic
                      be ported to town when you flag with it)
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      You cannot dual box in Events
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      You cannot dual box in Olympiad
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      You cannot dual box in Sieges
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      You cannot dual box in Epics
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      You cannot dual box in PvP Zones (such as mos and pagan,
                      epic zones etc)
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Maximum number of members in a party:9
                    </li>
                  </ul>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    GENERAL RATES
                  </div>
                  <ul className="list-disc ml-[1vw]">
                    <li className="opacity-50 font-[Gotham]">
                      Experience: x20
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Skill Points: x20
                    </li>
                    <li className="opacity-50 font-[Gotham]">Drop: x7</li>
                    <li className="opacity-50 font-[Gotham]">Adena: x10</li>
                    <li className="opacity-50 font-[Gotham]">Quest: x3</li>
                    <li className="opacity-50 font-[Gotham]">Spoil: x3</li>
                  </ul>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    ADITIONAL FEATURES AND CHANGES
                  </div>
                  <ul className="list-disc ml-[1vw]">
                    <li className="opacity-50 font-[Gotham]">
                      Autolearn skills until lvl 85
                    </li>
                    <li className="opacity-50 font-[Gotham]">Auto loot</li>
                    <li className="opacity-50 font-[Gotham]">
                      No Weight limit
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Shift + click on monsters to see the droplist
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      MP potions in Premium shop with Adena
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Offline shop (duration 7 days before being kicked)
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Slots - 26(+4) Buffs & 12 Song/Dances
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Clan skills for all members
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Missions system (from Classic), you can get rewards by
                      completing daily/weekly/one-time missions like killing
                      monsters, raids, players on pvp, epics, collecting certain
                      items…
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Block exp from Settings
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Restore life does not work in Raid Boss or Epic Boss
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      First class change: 10.000 Adena
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Second class change: 1.000.000
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Third class change: 15.000.000
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Subclass max level - 85
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Certification Quest - Not Required
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Noblesse Quest - Retail High Five version.
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Core and Queen Ant lvl 85
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Party lvl diference: 30 Lvls
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Buffer, Teleports, Shop up to S-Grade, Blacksmith, Symbol
                      Maker services and more through Community Board.
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Start initial quest: Reward of D-grade Equipment, Warcrown
                      Cloak and EXP Rune.
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Mana Potion - Restore 1000 MP with 10 seconds cooldown
                    </li>
                    <li className="opacity-50 font-[Gotham]">
                      Free 6 hours Auto-Farming system for everyone.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {features === 1 && (
            <div className="flex flex-col flex-1">
              <div className="mt-[1.8vw] bg-[url('./assets/img/wiki/features/enchantment_rates.png')] bg-no-repeat bg-contain w-[55.63vw] aspect-[1068/504]" />
              <div className="flex flex-col text-white text-[1vw] w-[93.2%] rounded-lg bg-[#0b333a4D] ml-[2.2vw] px-[2vw] py-[1vw] max-h-[57vw] overflow-auto custom-bar">
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Weapons
                  </div>
                  <table className="w-full text-left opacity-50">
                    <thead>
                      <tr>
                        <th className="font-[Gotham]">Level</th>
                        <th className="font-[Gotham]">Warrior weapons</th>
                        <th className="font-[Gotham]">mage weapons</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="font-[Gotham]">+0 → +3</td>
                        <td className="font-[Gotham]">100%</td>
                        <td className="font-[Gotham]">100%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+3 → +15</td>
                        <td className="font-[Gotham]">60%</td>
                        <td className="font-[Gotham]">40%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+15 → +16</td>
                        <td className="font-[Gotham]">30%</td>
                        <td className="font-[Gotham]">20%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-[2vw]">
                  <span className="block bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Armors (full body)
                  </span>
                  <table className="w-full text-left opacity-50">
                    <thead>
                      <tr>
                        <th className="font-[Gotham]">Level</th>
                        <th className="font-[Gotham]">Suuccess Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="font-[Gotham]">+0 → +4</td>
                        <td className="font-[Gotham]">100%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+4 → +5</td>
                        <td className="font-[Gotham]">66.6%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+5 → +6</td>
                        <td className="font-[Gotham]">33.3%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+6 → +7</td>
                        <td className="font-[Gotham]">25%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+7 → +8</td>
                        <td className="font-[Gotham]">20%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+8 → +9</td>
                        <td className="font-[Gotham]">16.6%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+9 → +10</td>
                        <td className="font-[Gotham]">14.2%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Armors and Jewels
                  </div>
                  <table className="w-full text-left opacity-50">
                    <thead>
                      <tr>
                        <th className="font-[Gotham]">Level</th>
                        <th className="font-[Gotham]">Suuccess Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="font-[Gotham]">+0 → +3</td>
                        <td className="font-[Gotham]">100%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+3 → +4</td>
                        <td className="font-[Gotham]">66.6%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+4 → +5</td>
                        <td className="font-[Gotham]">33.3%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+5 → +6</td>
                        <td className="font-[Gotham]">25%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+6 → +7</td>
                        <td className="font-[Gotham]">20%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+7 → +8</td>
                        <td className="font-[Gotham]">16.6%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+8 → +9</td>
                        <td className="font-[Gotham]">14.2%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+9 → +10</td>
                        <td className="font-[Gotham]">12.5%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    T-shirt
                  </div>
                  <table className="w-full text-left opacity-50">
                    <thead>
                      <tr>
                        <th className="font-[Gotham]">Level</th>
                        <th className="font-[Gotham]">Suuccess Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="font-[Gotham]">+0 → +3</td>
                        <td className="font-[Gotham]">100%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+3 → +4</td>
                        <td className="font-[Gotham]">66.6%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+4 → +5</td>
                        <td className="font-[Gotham]">33.3%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+5 → +6</td>
                        <td className="font-[Gotham]">25%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+6 → +7</td>
                        <td className="font-[Gotham]">20%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+7 → +8</td>
                        <td className="font-[Gotham]">16.6%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+8 → +9</td>
                        <td className="font-[Gotham]">14.2%</td>
                      </tr>
                      <tr>
                        <td className="font-[Gotham]">+9 → +10</td>
                        <td className="font-[Gotham]">12.5%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {features === 2 && (
            <div className="flex flex-col flex-1">
              <div className="mt-[1.8vw] bg-[url('./assets/img/wiki/features/game_client.png')] bg-no-repeat bg-contain w-[55.63vw] aspect-[1068/504]" />
            </div>
          )}
          {features === 3 && (
            <div className="flex flex-col flex-1">
              <div className="mt-[1.8vw] bg-[url('./assets/img/wiki/features/class_balance.png')] bg-no-repeat bg-contain w-[55.63vw] aspect-[1068/504]" />
              <div className="flex flex-col text-white text-[1vw] w-[93.2%] rounded-lg bg-[#0b333a4D] ml-[2.2vw] px-[2vw] py-[1vw] max-h-[57vw] overflow-auto custom-bar">
                <div className="flex flex-col text-center mt-[1vw]">
                  <span className="opacity-50 font-[Gotham]">
                    We want to offer players a unique experience - it's
                    significant and redesigned HF so we have added a balance
                    that will allow all races to play an important role in the
                    game.
                  </span>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Bounty hunter
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/bounty-hunter/rolling-dice.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Rolling Dice [from 54 lv:]
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      Roll of the dice.
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        The character can gain one of three effects: Increases
                        Speed by 15% and Speed by 15, Increases Physical Attack
                        by 15% and Crit chance by 30. Atk. by 30. Increases Max.
                        HP by 20% and absorbs 15% of damage dealt in HP,
                        excluding damage from skills and ranged attacks.
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration: 30 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Does not work in the Olympics
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Recycle enchanting branch: reduces the time until skill
                        reuse by 60 seconds
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/bounty-hunter/coin-luck.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Coin Luck [from 54 lv:]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        When using attack skills, increases Adena/Drop/Spoil
                        rares by 7% for the entire group with a certain
                        probability
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Action Time: 15 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Does not work in the Olympics
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Encahnting Power: Additionally reduces the cooldown of
                        all skills by 9%
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Warsmith
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/warsmith/mechanical-scream.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Mechanical Scream [from 48 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Terrorizes nearby enemies, causing them to flee
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Reduces movement speed by 90%
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration: 5 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Does not work in the Olympics
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power sharpening branch: Increases the skill's duration
                        to 12 seconds
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Recycle sharpening branch: reduces the time to reuse the
                        skill by 60 seconds
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/warsmith/provoke.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Provoke [from 43 lv.]
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      The skill lures monsters in the area to the character,
                      reduces defense against spear attacks by 10 for 10 seconds
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/warsmith/mechanical-blow.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Mechanical Blow [from 62 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Powerful attack on enemies
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        When using a crushing weapon, the character rushes
                        forward to a distance of 900, forcing enemies to lose
                        their aim
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        When using a spear, the character delivers a crushing
                        blow to enemies in front
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Does not work in the Olympics
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Recycle enchanting branch: reduces the time until skill
                        reuse by 60 seconds
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/warsmith/eye-of-slayer.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Eye of Slayer [from 78 lv.]
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      Increases Physical Attack against beasts/magical
                      creatures/giants/dragons by 30%
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/warsmith/eye-of-hunter.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Eye of Hunter [from 77 lv.]
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      Increases P.A. against insects/plants/animals by 30%
                    </div>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Temple knight
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/temple-knight/grand-provoke.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Grand Provoke [from 43 lv.]
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      Forces enemies to attack. Does not work in the Olympics
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/temple-knight/chain-hydra.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Chain Hydra [from 48 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Pulls the target with a chain
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Requires a shield
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Cast range: 600
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power enchanting branch: increases the number of targets
                        to 5
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power enchanting branch: increases the casting range to
                        1050
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/temple-knight/echo-shield.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Echo Shield [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Attacks enemies in front of you and deals additional
                        damage with a shield
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Can knock down the target
                      </li>
                      <li className="opacity-50 font-[Gotham]">Power: 3600</li>
                      <li className="opacity-50 font-[Gotham]">
                        Power enchnating branch: increases the power of the
                        skill to 5140
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/temple-knight/freezing-aura.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Freezing Aura [from 54 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Causes an aura around himself, the aura follows the
                        character
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Reduces the movement speed of nearby enemies by 15%, the
                        reuse time of physical and magical skills by 30%, Spd.
                        Atk. and Speed Mag. by 15%
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Requires a shield
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/temple-knight/touch-of-eva.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Touch of Eva
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      added an effect that adds 25% Damage and Defense in PvE
                    </div>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Dark avenger
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/dark-avenger/avenger-fury.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Avenger Fury [from 52 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        When using attack skills, with a certain probability for
                        the entire group: increases Spd. Atk. by 5%, Speed. Mag.
                        by 5% and Speed by 5
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Absorbs 6% of damage dealt in HP, excluding damage from
                        skill and ranged attacks
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration: 10 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympics
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Treasure hunter / Soulhound / Plainswalker / Abyss walker
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/treasure-hunter/hunters-clarity.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Hunters Clarity [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Significantly reduces MP consumption for physical and
                        magical skills, with a certain chance of restoring 10%
                        of the maximum MP reserve after killing an enemy
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Hawkeye / Silver ranger / Phantom ranger / Trickster
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/hawkeye/survival-instinct.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Survival Instinct [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        When attacking with a bow/crossbow, increases P. Atk. by
                        5%, restores 60 HP/sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration 10 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work in PvP
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power enchanting branch: increases critical power
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        attacks by 15%
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Prophet
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/prophet/might-of-heaven.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Might of Heaven [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Brings down the punishment of the gods on enemies and
                        deals damage with holy magic
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work on characters
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/prophet/prophecy-of-fire.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Prophecy of Fire
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      Additionally increases the chance of Crit Attack by 20%
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/prophet/piercing.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Piercing
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      Now available from level 52
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/prophet/fatal-crash.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Fatal Crash [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        When striking an enemy, reduces defense against melee
                        weapons by 15
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Requires a sword, club or dual swords
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Critical hit and super hit possible
                      </li>
                      <li className="opacity-50 font-[Gotham]">Power: 5704</li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration 10 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Cannot be used at the Olympics
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/prophet/soul-collector.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Soul Collector [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        With a certain chance, converts 10% of the physical
                        damage dealt to the enemy into MP
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/prophet/magic-wound.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Magic Wound [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        new passive skill
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        When using spells on a target, there is a 60% chance to
                        statically reduce magic
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        target's defense by 23%, debuff duration 8 seconds
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work on characters
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Elven Elder
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/elven-elder/might-of-heaven.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Might of Heaven [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Brings down the punishment of the gods on enemies and
                        deals damage with holy magic
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work on characters
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/elven-elder/mantra.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Mantra [from 52 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        For nearby allies: Reduces damage taken by 5% and magic
                        skill cooldown time by 5%
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Absorbs 3% of the damage dealt in MP
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration: 15 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/elven-elder/magic-wound.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Magic Wound [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        new passive skill
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        When using spells on a target, there is a 60% chance to
                        statically reduce magic
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        target's defense by 23%, debuff duration 8 seconds
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work on characters
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Shillen Elder
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/shillen-elder/might-of-heaven.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Might of Heaven [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Brings down the punishment of the gods on enemies and
                        deals damage with holy magic
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work on characters
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/shillen-elder/vampiric-fog.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Vampiric Fog [from 52 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        For nearby allies: Increases Speed by 10%, outgoing
                        damage by 5%, damage from Blow skills by 3% and absorbs
                        5% of damage dealt by physical skills in HP
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration: 15 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/shillen-elder/magic-wound.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Magic Wound [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        new passive skill
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        When using spells on a target, there is a 60% chance to
                        statically reduce magic
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        target's defense by 23%, debuff duration 8 seconds
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work on characters
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Swordsinger / Bladedancer
                  </div>
                  <div className="font-[Gotham] mt-5">
                    Classes have been updated for solo and party PvE play,
                    unique skills have been added, and the Dance/Song action
                    time has been increased to 5 minutes:
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/swordsinger/infinity-rush.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Infinity Rush [from 48 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Rapidly rushes towards the target from the front
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Reduces the speed of enemies by 100
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration: 4 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power enchanting branch: increases the cast range to 900
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Enchanting Cost branch: increases the duration of the
                        skill to 10 sec
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/swordsinger/deadly-smash-bd.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Deadly Smash (BD) [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Powerful attack on surrounding enemies, reduces P. Def.
                        by 10%, M. Def. by 10%, power 2192
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Overshot possible
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Cannot be used at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/swordsinger/deadly-sweep-sws.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Deadly Sweep (SWS) [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Powerful attack on surrounding enemies, reduces P. Atk.
                        by 10%, M. Atk. by 10%, power 2192
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Overshot possible
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Cannot be used at the Olympiad
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power enchanting branch: increases the power of the
                        skill to 3370
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/swordsinger/soul-collector.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Soul Collector [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        With a certain chance, converts 10% of the physical
                        damage dealt to the enemy into MP
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Overlord
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/overlord/clear-mind.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Clear Mind [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Transfers 3% of magical damage dealt to MP
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/overlord/bloodlust.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Bloodlust [from 52 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        For 15 seconds, all your skills will consume HP instead
                        of MP
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                    </ul>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/overlord/bloody-chain.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Bloody Chain [from 40 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Attacks with a chain strike and additionally inflicts
                        108 damage to the target and the enemy around the
                        target. non-elemental damage
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Absorbs 10% of HP damage dealt
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Reduces P. Def. by 5%
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration: 10 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympiad
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power enchanting branch: increases the power of the
                        skill to 119
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power enchanting branch: reduces P. Def. by 13%
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Warcryer
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/warcryer/great-fury.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Great Fury
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      Now learnable at level 48, additionally triggered by bow
                      shots with a 15% chance, adds 10% / 20% / 30% attack speed
                      for 8 seconds
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/warcryer/full-focus.png')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Full Focus [from 52 lv.]
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        Increases maximum attack distance by 100, arrow reload
                        speed by 25% when using a bow/crossbow
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Duration: 15 sec
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Does not work in the Olympiad
                      </li>
                    </ul>
                    <div className="font-[Gotham]">
                      Also the class got new skills:
                    </div>
                    <ul className="list-disc">
                      <li className="flex items-end gap-3 py-1 font-[Gotham]">
                        <div className="bg-[url('./assets/img/wiki/description/class_balance/warcryer/long-shot.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                        Long Shot
                      </li>
                      <li className="flex items-end gap-3 py-1 font-[Gotham]">
                        <div className="bg-[url('./assets/img/wiki/description/class_balance/warcryer/bow-mastery.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                        Bow Mastery
                      </li>
                      <li className="flex items-end gap-3 py-1 font-[Gotham]">
                        <div className="bg-[url('./assets/img/wiki/description/class_balance/warcryer/archers-will.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                        Archer's Will
                      </li>
                      <li className="flex items-end gap-3 py-1 font-[Gotham]">
                        <div className="bg-[url('./assets/img/wiki/description/class_balance/warcryer/archery.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                        Archery
                      </li>
                      <li className="flex items-end gap-3 py-1 font-[Gotham]">
                        <div className="bg-[url('./assets/img/wiki/description/class_balance/warcryer/rapid-shot.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                        Rapid Shot
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] uppercase font-bold font-[Gotham] text-[1.2vw]">
                    Soultaker
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/soultaker/mass-warrior-bane.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Mass Warrior Bane
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      now the skill imposes a debuff for 4 seconds, prohibiting
                      the rebuff of canceled skill
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/soultaker/mass-mage-bane.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Mass Mage Bane
                    </div>
                    <div className="opacity-50 font-[Gotham]">
                      now the skill imposes a debuff for 4 seconds, prohibiting
                      the rebuff of canceled skills
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="flex items-end gap-3 font-[Gotham]">
                      <div className="bg-[url('./assets/img/wiki/description/class_balance/soultaker/corpse-burst.jpg')] bg-no-repeat bg-contain w-[34px] aspect-[1/1]" />
                      Corpse Burst
                    </div>
                    <ul className="list-disc ml-[1vw]">
                      <li className="opacity-50 font-[Gotham]">
                        can now be targeted to all monsters (previously it was
                        only possible to target corpses)
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Power in PvE has been reduced
                      </li>
                      <li className="opacity-50 font-[Gotham]">
                        Doesn't work at the Olympics
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          {features === 4 && (
            <div className="flex flex-col flex-1">
              <div className="mt-[1.8vw] bg-[url('./assets/img/wiki/features/streaming_rewards.png')] bg-no-repeat bg-contain w-[55.63vw] aspect-[1068/504]" />
              <div className="flex flex-col text-white text-[1vw] w-[93.2%] rounded-lg bg-[#0b333a4D] ml-[2.2vw] px-[2vw] py-[1vw] max-h-[57vw] overflow-auto custom-bar">
                <div className="flex flex-col text-center mt-[1vw]">
                  <span className="opacity-50 font-[Gotham]">
                    On L2Warcrown, you can receive L2Coins by actively streaming
                    gameplay of our server on Twitch.tv. We hope this will bring
                    new players to our community and make server even more
                    populated. In order to receive rewards, you need to register
                    your stream on your main character in game.
                  </span>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] font-[Gotham]">
                    Click on General Menu(ALT+B) and select STREAM
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] font-[Gotham]">
                    You can then register your channel. To do so, your stream
                    must be currently running and its title must contain the
                    text "L2Warcrown"
                  </div>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] font-[Gotham]">
                    The reward you will receive will depend on the number of
                    hours your sequence has:
                  </div>
                  <span className="opacity-50 font-[Gotham]">
                    An important condition to receive a reward is that your
                    stream has a minimum of 10 viewers. For each hour of stream
                    you will receive 2 Lcoin.
                  </span>
                </div>
                <div className="mt-[2vw]">
                  <div className="bg-[#0b333aB3] rounded-lg p-[0.5vw] font-[Gotham]">
                    There are also limitations:
                  </div>
                  <div className="opacity-50 font-[Gotham]">
                    The player must stream doing some activity with his
                    character, if the player streams with the character absent,
                    his counter and rewards will be reset.
                  </div>
                  <div className="opacity-50 font-[Gotham]">
                    We are aware that being generous with rewards may encourage
                    players to create fake streams with fake viewers. In case
                    this happens, the reward list will be adjusted.
                  </div>
                </div>
              </div>
            </div>
          )}
          {features === 5 && (
            <div className="flex flex-col flex-1">
              <div className="mt-[1.8vw] bg-[url('./assets/img/wiki/features/raid_bosses.png')] bg-no-repeat bg-contain w-[55.63vw] aspect-[1068/504]" />
            </div>
          )}
          {features === 6 && (
            <div className="flex flex-col flex-1">
              <div className="mt-[1.8vw] bg-[url('./assets/img/wiki/features/power_shirt.png')] bg-no-repeat bg-contain w-[55.63vw] aspect-[1068/504]" />
            </div>
          )}
          {features === 7 && (
            <div className="flex flex-col flex-1">
              <div className="mt-[1.8vw] bg-[url('./assets/img/wiki/features/l2_coinstore.png')] bg-no-repeat bg-contain w-[55.63vw] aspect-[1068/504]" />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}
